import React from "react"
import { graphql } from "gatsby"


import Nav from "../Components/Nav"
import LandingPage from "../Components/Home/LandingPage"
import OurGames from "../Components/Home/OurGames"
import Careers from "../Components/Home/Careers"
import OurPhilosophy from "../Components/Home/OurPhilosophy"
import Blogs from "../Components/Home/Blogs"
import Footer from "../Components/Footer"

import SEO from "../Components/SEO"

function Home({ data }){
    const { edges: posts } = data.allMarkdownRemark
    const { nodes: games } = data.allGamesJson
    return(
        <main className="home">
            <SEO title="Funovus" keywords="wild sky, wild castle, grow castle, Strategy games, TD games, RTS games" />
            <Nav />
            <LandingPage/>
            <OurGames game={games[0]}/>
            <Careers />
            <OurPhilosophy />
            <Blogs blogs={posts.slice(0, 3)}/>
            <Footer />
        </main>
    )
}

export default Home

export const pageQuery = graphql`
  query HomeQuery {
    allGamesJson(filter: {order: {eq: 1}}) {
      nodes {
        name
        path
        appIcon
        category
        description
        featureImage
        gameStats {
          downloads
          reviewers
          score
        }
        gameDetails {
          featureBackground
        }
        social {
          facebook
          instagram
          youtube
        }
      }
    }
    allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/(posts)/"
      }
    }, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`