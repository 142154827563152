import React from "react"
import { Link } from "gatsby"

function LandingPage(){
    return(
        <section className="section" id="landing">
            <div className="landingImgLeft">
                <img src="https://i.imgur.com/DMKqZDy.png" alt="Welcome to Funovus!" />
            </div>
            <div className="landingImgRight">
                <picture>
                    <source srcSet="https://i.imgur.com/gpR1tU4.png" media="(min-width: 770px)" />
                    <img src="https://i.imgur.com/SpBsxzg.png" alt="Welcome to Funovus!" />
                </picture>
            </div>
            <div className="landingContent">
                <div className="landingText">
                    <div className="tagLine">
                        <em>Fun</em> + <em>Novus</em> = <em>Fun of Us!</em>
                    </div>
                    <p className="introText">
                        Reimagining Game Development
                    </p>
                    <div className="buttonGroup">
                        <a href="#ourPhilosophy">
                            <button className="c2a">Learn More</button>
                        </a>
                        <Link to="/careers">
                            <button>Open Positions</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingPage