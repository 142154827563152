import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

const philosophyItems = [
    {
        img: "https://i.imgur.com/E2rx6nY.png",
        name: "Efficient Development",
        description: <>We develop games in <b>small, lean, and agile</b> teams. Thanks to our powerful in-house game editor, we're able to quickly <b>find the fun</b> and <b>iterate fast.</b></>
    },
    {
        img: "https://i.imgur.com/LIQLED0.png",
        name: "Creative Freedom",
        description: <>Our designers have <b>great ownership</b> of the games they work on. This creative freedom lets us <b>explore unique art styles and gameplay</b> and helps us build better games.</>
    },
    {
        img: "https://i.imgur.com/huvAESI.png",
        name: "Quality Driven",
        description: <>We run monthly <b>design workshops</b> and <b>game deconstructions</b> to constantly hone our craft. To help our team wind down we offer <b>regular game nights</b> and an annual <b>games stipend</b>.</>
    },
    {
        img: "https://i.imgur.com/dIdwIlX.png",
        name: "Global Vision",
        description: <>With a global team, we hire people worldwide, and <b>provide visa sponsorship</b> for international students. We aim to reach players everywhere!</>
    }
]

export default function OurPhilosophy(){
    return <section className="section" id="ourPhilosophy" >
        <div className="philosophyContent">
            <div className="sectionHeader">Our Philosophy</div>
            <div className="grid">
                {philosophyItems.map(item => <div className="item" key={item.name}>
                    <div className="imgContainer">
                        <img src={item.img} alt={item.name} />
                    </div>
                    <div className="description">
                        <h3>{item.name}</h3>
                        <p>{item.description}</p>
                    </div>
                </div>)}
            </div>
            <div className="absoluteBtnContainer">
                <Link to="/careers">
                    <button>
                        <FontAwesomeIcon icon={faCaretRight} /> View Open Positions
                    </button>
                </Link>
            </div>
        </div>
    </section>
}