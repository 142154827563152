import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

export default function Careers(){
    return <section 
        className="section" 
        id="careers" 
        style={{ 
            backgroundImage: `url("https://i.imgur.com/f616dO3.jpg")`,
            backgroundSize: "cover",
        }}
    >
        <div className="careersContent">
            <div className="hero">
                <img src="https://i.imgur.com/N06FQxe.png" alt="Careers at Funovus" />
            </div>
            <div className="desc">
                <h1>Careers</h1>
                <p>
                    We strive to make quality games that reach players worldwide. Our designers and artists have a lot of creative freedom to make games as they see fit. With our internal tools, we can develop games from concept to production in a matter of months.
                </p>
                <Link to="/careers">
                    <button>
                        <FontAwesomeIcon icon={faCaretRight} /> Explore Careers...
                    </button>
                </Link>
            </div>
        </div>
    </section>
}