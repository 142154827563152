import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";

import GameStats from "../GameStats";

export default function Games({ game }){
    const { name, path, category, description, appIcon, gameDetails } = game;
    return <section 
        className="section" 
        id="ourGames" 
        style={{
            backgroundImage: `url(${gameDetails.featureBackground})`, 
            backgroundSize: "cover",
        }}
    >
        <div className="gameInfo">
            <div className="gameHeader">
                <Link to={path}>
                    <img src={appIcon} alt={name} />
                </Link>
                <div className="gameMeta">
                    <h5 className="gameType">{category}</h5>
                    <h1 className="gameName">{name}</h1>
                </div>
            </div>
            <GameStats game={game} />
            <p className="gameDesc">{description}</p>
            <div>
                <Link to="/games">
                    <button>
                        <FontAwesomeIcon icon={faCaretRight} /> More Games...
                    </button>
                </Link>
            </div>
        </div>
    </section>
}